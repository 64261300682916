import darsan from "darsan"
import sysconf from "sysconf"
import _ from "underscore"

export default [
  {
    name: "check-devices",
    title: "Проверка работы устройств",
    async fn(facts)
    {
      const uid = facts.uid 
      const domain = sysconf.domainByUid(facts.uid)

      const macs = facts.ppp.mac.filter(el => el!="" && el!="*")
      const seen = await Promise.all(macs.map(el => darsan.getm(domain, "device", `/mac/${el}/seen2`).catch(err => undefined)))

      const ppp_devices = _.flatten([ ...seen.filter(el => el).map(el => el.current), ...seen.filter(el => el).map(el => el.last_seen) ])
      const ipoe_devices = facts.ipoe.map(el => { return { entity: el.device_entity, port: el.device_port, kind: el.device_type} } )
      const devices = _.uniq([...ppp_devices, ...ipoe_devices], false, el => `${el.entity}:${el.port}`)
      
      const switches = devices.filter(el => el.kind=="switch")
      const trees = devices.filter(el => el.kind=="pontree")
      
      const switch_status = await Promise.all(switches.map(el => darsan.getm(domain, "device", el.entity)))
      switch_status.forEach( (el,i) => el.port = switches[i].port)
      
      const switch_down = switch_status.filter(el => el.down)
      facts["device-down"] = switch_down.map(el => { return { device: el.name } })
      
      const switch_port_adm_off = switch_status.filter( (el,i) => el.ports_adm_on[el.port-1] == "0" )
      facts["device-port-adm-off"] = switch_port_adm_off.map(el => { return { device: el.name, port: el.port } })

      const switch_port_off = switch_status.filter( (el,i) => el.ports_on[el.port-1] == "0" )
      facts["device-port-off"] = switch_port_off.map(el => { return { device: el.name, port: el.port } })

      const tree_status = await Promise.all(trees.map(el => darsan.getm(domain, "device", `${el.entity}/port/${el.port}/status`)))
      
      const tree_deregistered = tree_status.filter(el => el.deregistered)
      facts["pontree-deregistered"] = tree_deregistered.map(el => { return { head: el.head, device: el.name, reason: el.deregistered } })
      
      const tree_bad_level = tree_status.filter(el => !el.deregistered && (el.onu_rx > 4 || el.onu_rx < -28))
      facts["pontree-bad-level"] = tree_bad_level.map(el => { return { head: el.head, device: el.name, onu_rx: el.onu_rx } })
    }
  },
]
