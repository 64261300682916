import _ from "underscore"
import jsonpath from "jsonpath"
import moment from "moment"
import {formatUptime} from "common"
import percentile from "percentile"

export function value(val, path)
{
  if (!path) return val
  if (!_.isArray(val) && !_.isObject(val)) return val
  
  const parts = path.split("#")
  const p = parts.shift()
  const processed = p ? 
     _.isArray(val) || p.indexOf("[") !== -1 ? jsonpath.query(val, p) : jsonpath.value(val, p)
     :
     val

  return applyFunctions(parts, processed)
}

function applyFunctions(funs, val)
{
  for (const fn of funs)
  {
    val = apply(fn, val)
  }
  
  return val
}

function apply(func, src)
{
  let res = undefined
  switch(func)
  {
    case "passed-days":
       res = moment().diff(moment(src, "YYYY-MM-DD"), 'days')
    break
    case "passed-seconds":
       res = moment().diff(moment(src), 'seconds')
    break
    case "format-uptime":
       res = formatUptime(src)
    break
    case "length":
       res = _.isArray(src) ? src.length : 1
    break
    case "value":
       res = _.isArray(src) ? src[0] : src
    break
    case "percentile20":
       res = percentile(20, src)
    break
    case "percentile50":
       res = percentile(50, src)
    break
    default:
      throw new Error("aux/apply: Unknown function '"+func+"'")
  }

  return res
}
