const jp = require("jsonpath")

const aaa = 1

const ipoe = 
[
    {
        current: [
          {
            dev: "john",
            kind: "pon",
          },
          {
            dev: "paul",
          },  
        ],
        on: "010",
        port: 2,
    },
    {
        current: [
          {
            dev: "meaow",
            kind: "pon",
          },
        ],
        last: [
            {
              dev: "hello",
              kind: "pon",
            },
        ],
        on: "001",
        port: 3,
    }
]

console.log(jp.query(ipoe, "$[(3-2)]"))